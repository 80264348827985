<template>
  <div class="container">
    <!--上部分-->
    <el-row :gutter="20">
      <el-col
        v-if="roleCode === 'canteen'"
        :xs="24"
        :sm="24"
        :md="16"
        :lg="16"
        :xl="16"
      >
        <el-card shadow="never">
          <div slot="header" class="clearfix">
            <span>今日订餐统计</span>
          </div>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <el-card shadow="never" :body-style="{ padding: '0px' }">
                <div class="grid-content grid-con-1">
                  <i class="el-icon-dish grid-con-icon">订餐预约</i>
                  <div class="grid-cont-right">
                    <div class="grid-num">{{ cantentTotal.totalA }}</div>
                    <div class="grid-list">
                      <ul>
                        <li>
                          <span>早餐：</span>
                          <b>
                            {{
                              canteenStatistics.appointmentCountDto
                                .breakfastNums
                            }}
                          </b>
                        </li>
                        <li>
                          <span>中餐：</span>
                          <b>
                            {{
                              canteenStatistics.appointmentCountDto.lunchNums
                            }}
                          </b>
                        </li>
                        <li>
                          <span>晚餐：</span>
                          <b>
                            {{
                              canteenStatistics.appointmentCountDto.dinnerNums
                            }}
                          </b>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <el-card shadow="never" :body-style="{ padding: '0px' }">
                <div class="grid-content grid-con-2">
                  <i class="el-icon-dish grid-con-icon">临时预约</i>
                  <div class="grid-cont-right">
                    <div class="grid-num">{{ cantentTotal.totalT }}</div>
                    <div class="grid-list">
                      <ul>
                        <li>
                          <span>早餐：</span>
                          <b>
                            {{ canteenStatistics.tempCountDto.breakfastNums }}
                          </b>
                        </li>
                        <li>
                          <span>中餐：</span>
                          <b>{{ canteenStatistics.tempCountDto.lunchNums }}</b>
                        </li>
                        <li>
                          <span>晚餐：</span>
                          <b>{{ canteenStatistics.tempCountDto.dinnerNums }}</b>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <el-card shadow="never" :body-style="{ padding: '0px' }">
                <div class="grid-content grid-con-3">
                  <i class="el-icon-dish grid-con-icon">招待预约</i>
                  <div class="grid-cont-right">
                    <div class="grid-num">{{ cantentTotal.totalE }}</div>
                    <div class="grid-list">
                      <ul>
                        <li>
                          <span>早餐：</span>
                          <b>
                            {{
                              canteenStatistics.entertainCountDto.breakfastNums
                            }}
                          </b>
                        </li>
                        <li>
                          <span>中餐：</span>
                          <b>
                            {{ canteenStatistics.entertainCountDto.lunchNums }}
                          </b>
                        </li>
                        <li>
                          <span>晚餐：</span>
                          <b>
                            {{ canteenStatistics.entertainCountDto.dinnerNums }}
                          </b>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <!--下部分-->
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card v-if="tabList.length > 0" shadow="never">
          <el-link
            type="primary"
            style="position: absolute;right: 10px;"
            @click="onRefresh"
            >刷新</el-link
          >
          <div class="clearfix">
            <el-tabs :value="pageInfo.module" @tab-click="tabClick">
              <el-tab-pane
                v-for="item in tabList"
                :key="item.id"
                :name="item.code"
              >
                <span slot="label">
                  <el-badge
                    :type="item.badge === 0 ? 'info' : 'danger'"
                    :value="item.badge"
                    :max="99"
                    class="item"
                    >{{ item.title }}</el-badge
                  >
                </span>
              </el-tab-pane>
            </el-tabs>
          </div>
          <!--数据表格-->
          <el-table
            v-loading="listLoading"
            :data="dataList"
            element-loading-text="正在加载 ..."
            height="430px"
            fit
            highlight-current-row
            @row-dblclick="onView"
          >
            <el-table-column type="index" width="55" />
            <el-table-column label="姓名" prop="name" />
            <el-table-column label="预约时间" prop="orderDate" />
            <el-table-column label="预约内容描述" prop="content" />
            <el-table-column label="操作" width="120" align="center">
              <template slot-scope="scope">
                <el-link type="primary" @click="onView(scope.row)"
                  >查看</el-link
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              :current-page="pageInfo.startPage"
              :page-sizes="[10, 20, 30, 50, 100, 200]"
              :page-size="pageInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="onSizeChange"
              @current-change="onPageChange"
            />
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getTabListByUserid } from '@/api/admin/user';
import {
  getAllListByModule,
  countCanteenNums,
} from '@/api/dashboard';
import request from '@/utils/request';
var echarts = require('echarts');

export default {
  name: 'Dashboard',
  data() {
    return {
      name: sessionStorage.getItem('username'),
      roleCode: sessionStorage.getItem('roleCode'),
      userId: sessionStorage.getItem('userId'),
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0,
        userId: sessionStorage.getItem('userId'),
        module: '',
        roleCode: ''
      },
      canteenStatistics: {
        appointmentCountDto: {
          breakfastNums: 0,
          lunchNums: 0,
          dinnerNums: 0
        },
        tempCountDto: {
          breakfastNums: 0,
          lunchNums: 0,
          dinnerNums: 0
        },
        entertainCountDto: {
          breakfastNums: 0,
          lunchNums: 0,
          dinnerNums: 0
        }
      },
      cantentTotal: {
        totalA: 0,
        totalT: 0,
        totalE: 0
      },
      dataList: [],
      tabList: [],
      multipleSelection: [],
      listLoading: false,
      message: ''
    };
  },
  watch: {
    //监控坐标数组变化，执行弹窗动画
    tabList: function(newList, oldList) {
      oldList.forEach((item, index) => {
        const result = item.badge != newList[index].badge;
        // debugger;
        if (result) {
          this.$message({
            message: '有新申请',
            type: 'success',
            duration: 5000
          });
          let audio = new Audio();
          audio.src = '/12270.wav';
          audio.play();
        }
      });
    }
  },
  created() {
    this.autoUpdateCanteenNums();
    this.getMenuTabs();
    this.getCanteenStatistics();
  },
  methods: {
    initWebSocket: function() {
      if (typeof WebSocket === 'undefined') {
        console.log('您的浏览器不支持socket');
      } else {
        var baseUrl = request.defaults.baseURL;
        if (baseUrl.indexOf('https') != -1) {
          baseUrl = baseUrl.replace('https', 'wss');
        } else {
          baseUrl = baseUrl.replace('http', 'ws');
        }
        const wsuri = baseUrl + '/websocket/chat/1181459535554105345';
        this.websock = new WebSocket(wsuri);
        this.websock.onmessage = this.onMessage;
        this.websock.onopen = this.onOpen;
        this.websock.onerror = this.onError;
        this.websock.onclose = this.onClose;
      }
    },
    onOpen() {
      console.log('socket建立连接..');
    },
    onError() {
      this.initWebSocket();
    },
    onMessage(e) {
      console.log(e.data);
    },
    onClose(e) {
      console.log('断开连接', e);
    },
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchList();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchList();
    },
    //TAB点击
    tabClick(tab, event) {
      let module = tab.name;
      this.pageInfo.module = module;
      this.fetchList();
    },
    onRefresh() {
      this.fetchList();
      this.getMenuTabs();
    },
    //查询预约列表
    fetchList() {
      this.listLoading = true;
      this.pageInfo.roleCode = this.roleCode;
      getAllListByModule(this.pageInfo).then(resp => {
        this.dataList = resp.data.records;
        this.pageInfo.total = resp.data.total;
        this.pageInfo.startPage = resp.data.current;
        this.pageInfo.pageSize = resp.data.size;
        this.listLoading = false;
      });
    },
    //食堂统计
    getCanteenStatistics() {
      countCanteenNums().then(resp => {
        if (resp.code === 200) {
          this.canteenStatistics = resp.data;
          let res = resp.data;
          let A_breakfastNums = res.appointmentCountDto.breakfastNums;
          let A_lunchNums = res.appointmentCountDto.lunchNums;
          let A_dinnerNums = res.appointmentCountDto.dinnerNums;

          let T_breakfastNums = res.tempCountDto.breakfastNums;
          let T_lunchNums = res.tempCountDto.lunchNums;
          let T_dinnerNums = res.tempCountDto.dinnerNums;

          let E_breakfastNums = res.entertainCountDto.breakfastNums;
          let E_lunchNums = res.entertainCountDto.lunchNums;
          let E_dinnerNums = res.entertainCountDto.dinnerNums;

          //总计
          let totalA = A_breakfastNums + A_lunchNums + A_dinnerNums;
          let totalT = T_breakfastNums + T_lunchNums + T_dinnerNums;
          let totalE = E_breakfastNums + E_lunchNums + E_dinnerNums;
          this.cantentTotal.totalA = totalA;
          this.cantentTotal.totalT = totalT;
          this.cantentTotal.totalE = totalE;
        }
      });
    },
    autoUpdateCanteenNums() {
      let timer1 = null;
      let timer2 = null;
      if (this.roleCode === 'admin' || this.roleCode.indexOf('canteen') != -1) {
        timer1 = setInterval(() => {
          this.getCanteenStatistics();
        }, 15 * 1000);
      }

      timer2 = setInterval(() => {
        this.onRefresh();
      }, 15 * 1000);

      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer1);
        clearInterval(timer2);
      });
    },
    //查看
    onView(row) {
      let url = '';
      if (row.module === 'sleep') {
        url = '/sleep/list';
      } else if (row.module === 'renting') {
        url = '/renting/list';
      } else if (row.module === 'movie') {
        url = '/appointment/movie/main';
      } else if (row.module === 'device') {
        url = '/device/repair';
      } else if (row.module.indexOf('canteen') != -1) {
        url = '/' + row.module;
      } else {
        url = '/appointment/' + row.module;
      }
      this.$router.push(url);
    },
    //加载TAB菜单
    getMenuTabs() {
      getTabListByUserid({ userid: this.userId }).then(resp => {
        if (resp.data && resp.data.length > 0) {
          this.tabList = resp.data;
          this.pageInfo.module = this.tabList[0].code;
          this.fetchList();
        }
      });
    },
  }
};
</script>

<style scoped>
.item {
  top: 8px !important;
  padding-bottom: 45px;
}
.el-row {
  margin-bottom: 20px;
}
.grid-content {
  display: flex;
  align-items: center;
  height: 140px;
}
.grid-cont-right {
  flex: 1;
  text-align: center;
  color: gray;
}
.grid-num {
  font-size: 30px;
  font-weight: bold;
}
.grid-con-icon {
  font-size: 18px;
  width: 120px;
  height: 140px;
  text-align: center;
  line-height: 140px;
  color: #fff;
}
.grid-con-1 .grid-con-icon {
  background: rgb(45, 140, 240);
}
.grid-con-1 .grid-num {
  color: rgb(45, 140, 240);
}
.grid-con-2 .grid-con-icon {
  background: rgb(100, 213, 114);
}
.grid-con-2 .grid-num {
  color: rgb(45, 140, 240);
}
.grid-con-3 .grid-con-icon {
  background: rgb(242, 94, 67);
}
.grid-con-3 .grid-num {
  color: rgb(242, 94, 67);
}
.user-info {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
}
.user-avator {
  border-radius: 50%;
  max-width: 120px;
  display: block;
}
.user-info-cont {
  padding-left: 50px;
  flex: 1;
  font-size: 14px;
  color: #999;
}
.user-info-cont div:first-child {
  font-size: 30px;
  color: #222;
}
.user-info-list {
  font-size: 14px;
  color: #999;
  line-height: 25px;
}
.user-info-list span {
  margin-left: 70px;
}
.grid-list {
  padding: 10px;
  box-sizing: border-box;
  -moz-box-sizing: border-box; /* Firefox */
  -webkit-box-sizing: border-box; /* Safari */
  width: 100%;
  float: left;
  border-top: 1px solid #dbdbdb;
}
.grid-list ul li {
  list-style: none;
  height: 24px;
  line-height: 24px;
}
.grid-list ul li b,
.grid-list ul li span {
  font-size: 18px;
}
.text-item ul {
  width: 100%;
}
.text-item ul li {
  list-style: none;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  text-align: left;
}
</style>
