import request from '@/utils/request';

/**
 * 查询所有模块预约列表
 * @param {Object} data
 */
export function getAllListByModule(data) {
  return request({
    url: '/appointment/getAllListByModule',
    method: 'post',
    data
  });
}

/**
 * 查询所有模块预约列表
 * @param {Object} data
 */
export function countCanteenNums() {
  return request({
    url: '/canteen/appointment/countCanteenNums',
    method: 'post'
  });
}

/**
 * 获取系统参数
 */
export function getSystemInfos() {
  return request({
    url: '/system/getSysInfos',
    method: 'get'
  });
}
